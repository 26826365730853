@font-face {
  font-family: Nerd Font;
  src: url(../../../fonts/nerd-font.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}

.term {
  border-radius: 0.5rem;
  background: #0c0d0ee0;
  max-width: 35rem;
  margin: 1rem auto;
  padding: 1rem;
  text-align: left;
  width: 100%;

  code {
    font-family: Nerd Font,Source Code Pro,Menlo,Monaco,Consolas,Courier New,monospace;
    background: transparent;
  }

  .cyan {
    color: teal;
  }

  .yellow {
    color: color(secondary);
  }

  .green {
    color: color(tertiary);
  }

  .magenta {
    color: #82269c;
  }
}
